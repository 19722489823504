/* 

TemplateMo 586 Scholar

https://templatemo.com/tm-586-scholar

*/

/* ---------------------------------------------
Table of contents
------------------------------------------------
01. font & reset css
02. reset
03. global styles
04. header
05. banner
06. features
07. testimonials
08. contact
09. footer

--------------------------------------------- */
/* 
---------------------------------------------
font & reset css
--------------------------------------------- 
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900");
/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, div
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
figure, header, nav, section, article, aside, footer, figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header, nav, section, article, aside, footer, hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html, body {
  font-family: 'Poppins', sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6 {
  color: #1e1e1e;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 700;
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 14px;
  line-height: 28px;
  color: #4a4a4a;
}

img {
  width: 100%;
  overflow: hidden;
}

/* 
---------------------------------------------
Global Styles
--------------------------------------------- 
*/
html,
body {
  font-family: 'Poppins', sans-serif;
}

::selection {
  background: #7a6ad8;
  color: #fff;
}

::-moz-selection {
  background: #7a6ad8;
  color: #fff;
}

.section {
   margin-top: -100px;
 
}

.section-heading {
  margin-bottom: 60px;
}

.section-heading h2 {
  font-size: 36px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 20px 0;
  line-height: 44px;
}

.section-heading h2 em {
  color: #ffd83e;
  font-style: normal;
}

.section-heading h6 {
  color: #ffde59;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
}

.main-button a {
  display: inline-block;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  padding: 0px 25px;
  border-radius: 20px;
  transition: all .3s;
}

.main-button a:hover {
  background-color: #7a6ad8;
  color: #fff;
}

/* 
---------------------------------------------
Pre-loader Style
--------------------------------------------- 
*/

.js-preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.99);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  z-index: 9999;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.js-preloader.loaded {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

@-webkit-keyframes dot {
  50% {
      -webkit-transform: translateX(96px);
      transform: translateX(96px);
  }
}

@keyframes dot {
  50% {
      -webkit-transform: translateX(96px);
      transform: translateX(96px);
  }
}

@-webkit-keyframes dots {
  50% {
      -webkit-transform: translateX(-31px);
      transform: translateX(-31px);
  }
}

@keyframes dots {
  50% {
      -webkit-transform: translateX(-31px);
      transform: translateX(-31px);
  }
}

.preloader-inner {
  position: relative;
  width: 142px;
  height: 40px;
  background: transparent;
}

.preloader-inner .dot {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 12px;
  left: 15px;
  background: #ffde59;
  border-radius: 50%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-animation: dot 2.8s infinite;
  animation: dot 2.8s infinite;
}

.preloader-inner .dots {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  margin-top: 12px;
  margin-left: 31px;
  -webkit-animation: dots 2.8s infinite;
  animation: dots 2.8s infinite;
}

.preloader-inner .dots span {
  display: block;
  float: left;
  width: 16px;
  height: 16px;
  margin-left: 16px;
  background: #ffde59;
  border-radius: 50%;
}



/* 
---------------------------------------------
Header Style
--------------------------------------------- 
*/

.background-header {
  background-color: #7a6ad8 !important;
  border-radius: 0px 0px 25px 25px;
  height: 80px!important;
  position: fixed!important;
  top: 0!important;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.15)!important;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
}

.header-area {
  position: absolute;
  background-color: transparent;
  top:  0px;
  left: 0;
  right: 0;
  z-index: 100;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
}

.header-area .main-nav {
  background: transparent;
  display: flex
}

.header-area .main-nav .logo {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  display: inline-block;
}

.header-area .main-nav .logo h1 {
  font-size: 36px;
  text-transform: uppercase;
  color: #000000;
  font-weight: 600;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.background-header .main-nav .logo,
.background-header .main-nav #search {
  margin-top: 30px;
}

.header-area .main-nav #search {
  position: relative;
}

.header-area .main-nav #search input {
  background-color: #3d361560;
  height: 44px;
  width: 250px;
  border-radius: 23px;
  border: none;
  color: #ffde59;
  font-size: 14px;
  font-weight: 300;
  padding: 0px 20px 0px;
}

.header-area .main-nav #search input::placeholder {
  color: #ffde598c;
}

.header-area .main-nav #search i {
  position: absolute;
  color: #ffde59;
  right: 20px;
  top: 15px;
  font-size: 16px;
}

.header-area .main-nav ul.nav {
  border-radius: 0px 0px 25px 25px;
  flex-basis: 100%;
  margin-right: 0px;
  justify-content: right;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 999;
  margin-top: 30px;
}

.header-area .main-nav .nav li:last-child {
  padding-right: 0px;
}

.header-area .main-nav .nav li {
  padding-left: 5px;
  padding-right: 5px;
  height: 40px;
  line-height: 40px;
}
/* anchor*/
.header-area .main-nav .nav li a {
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  font-weight: 300;
  font-size: 14px;
  height: 40px;
  line-height: 26px;
  text-transform: capitalize;
  color: #ffde59;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  border: transparent;
  letter-spacing: .25px;
}

.background-header .main-nav .nav {margin-top: 20px;}

.background-header .main-nav .nav li {
  height: 80px;
  line-height: 80px;
}

.header-area .main-nav .nav li:hover a {
  opacity: 0.8;
}
.header-area .main-nav .nav li a.active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.10);
  opacity: 1;
}

.background-header .main-nav .nav li:hover a {
  opacity: 0.8;
}
.background-header .main-nav .nav li a.active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.10);
  opacity: 1;
}

.header-area .main-nav .menu-trigger {
  cursor: pointer;
  position: absolute;
  top: 33px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 20px;
  display: none;
}

.background-header .main-nav .menu-trigger {
  top: 20px;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #fff;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #fff;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: "";
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  -moz-transform-origin: 33% 100%;
  -ms-transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  -moz-transform-origin: 33% 0;
  -ms-transform-origin: 33% 0;
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
  -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #fff;
}

.background-header .main-nav .menu-trigger.active span:before {
  background-color: #6c5b5b;
}

.header-area .main-nav .menu-trigger.active span:after {
  -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #fff;
}

.background-header .main-nav .menu-trigger.active span:after {
  background-color: #fff;
}

.header-area.header-sticky .nav li a.active {
  /* color: #333333; */
  color:#ffde59;
}

.visible{
  display:inline !important;
}

@media (max-width: 1200px) {
  .header-area .main-nav .nav li {
    padding-left: 2px;
    padding-right: 2px;
  }
  .header-area .main-nav .nav li a {
    padding-left: 10px;
    padding-right: 10px;
    
  }

}

@media (max-width: 767px) {
  .background-header .main-nav .nav,
  .header-area .main-nav .nav {
    background-color: #ffde59;
  }
  .background-header .main-nav .nav li a,
  .header-area .main-nav .nav li a {
    line-height: 30px;
    height: 50px;
    font-weight: 400;
    color: #1e1e1e;
    background-color: transparent;
     border-radius: 25px 25px 25px 25px;
  }

  
  .background-header .main-nav .nav li,
  .header-area .main-nav .nav li {
    border-top: 1px solid #ddd;
    background-color: #f1f0fe;
    height: 50px;
    border-radius: 0px 0px 25px 25px;
  }
  .background-header .main-nav .nav li:last-child,
  .header-area .main-nav .nav li:last-child {
    border-radius: 0px 0px 25px 25px;
  }
  .header-area .main-nav .nav {
    height: auto;
    flex-basis: 100%;
  }
  .header-area .main-nav .logo {
    position: absolute;
    left: 30px;
    top: 0px;
  }
  .background-header .main-nav .logo {
    top: 0px;
  }
  .background-header .main-nav .border-button {
    top: 0px !important;
  }
  .header-area .main-nav .border-button {
    position: absolute;
    top: 15px;
    right: 70px;
  }
  .header-area.header-sticky .nav li a:hover,
  .header-area.header-sticky .nav li a.active {
    color: #7a6ad8!important;
    opacity: 1;
  }
  .header-area.header-sticky .nav li.search-icon a {
    width: 100%;
  }
  .header-area {
    background-color: transparent;
    padding: 0px 15px;
    height: 80px;
    box-shadow: none;
    text-align: center;
    color: #000;
  }
  .header-area .container {
    padding: 0px;
    color: #000;
  }
  .header-area .logo {
    margin-left: 0px;
    margin-top: 30px;
  }
  /* .header-area .menu-trigger {
    display: block !important;
  } */
  .header-area .main-nav {
    overflow: hidden;
  }
  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }
  .header-area .main-nav .nav li:first-child {
    border-top:transparent;
  }
  .header-area.header-sticky .nav {
    /*margin-top: 100px !important;*/
  }
  .background-header.header-sticky .nav {
    margin-top: 80px !important;
  }
  .header-area .main-nav .nav li {
    width: 100%;
    background: transparent;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
}

/* 
---------------------------------------------
Banner Style
--------------------------------------------- 
*/
.main-banner {
  background-image: url(./images/main-banner.webp);
  background-position: center top; /* Center the image horizontally and align it to the top */
  background-repeat: no-repeat;
  background-size: contain; /* Scale the image to cover the entire container */
  height: 1000px; /* Set a fixed height for the .main-banner */
  padding: 170px 0px 120px 0px;
}
/*
.main-banner {
  background-image: url(./images/soon.png);
 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  padding: 170px 0px 120px 0px;
}*/

/* .main-banner .item-1 {
  background-image: url(./images/banner-item-01.jpg);
}

.main-banner .item-2 {
  background-image: url(./images/banner-item-02.jpg);
}

.main-banner .item-3 {
  background-image: url(./images/banner-item-03.jpg);
} */

.main-banner .item {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 25px;
  padding: 100px 100px;
  margin-left: 130px;
}

.main-banner .item span.category {
  background-color: #7a6ad8;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 5px 15px;
  border-radius: 20px;
  display: inline-block;
  margin-bottom: 50px;
}

.main-banner .item h2 {
  font-size: 48px;
  font-weight: 700;
  color: #fff;
  line-height: 70px;
  width: 60%;
  margin-bottom: 40px;
}

.main-banner .item p {
  color: #fff;
  width: 65%;
}

.main-banner .item .buttons {
  display: flex;
  margin-top: 50px;
}

.main-banner .item .buttons .main-button {
  margin-right: 30px;
}

.main-banner .item .buttons .main-button a {
  font-size: 14px;
  color: #7a6ad8;
  background-color: #fff;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  padding: 0px 25px;
  border-radius: 25px;
  font-weight: 500;
}

.main-banner .item .buttons .icon-button i {
  color: #7a6ad8;
  background-color: #fff;
  display: inline-block;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 16px;
}

.main-banner .item .buttons .icon-button a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.main-banner .owl-nav {
  position: absolute;
  max-width: 1320px;
  bottom: 23px;
  left: 0;
  text-align: right;
}

.main-banner .owl-nav .owl-prev i,
.main-banner .owl-nav .owl-next i {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  display: inline-block;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  opacity: 1;
  transition: all .3s;
}

.main-banner .owl-nav .owl-prev i {
  position: absolute;
  bottom: 65px;
}

.main-banner .owl-nav .owl-prev i:hover,
.main-banner .owl-nav .owl-next i:hover {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.5);
}


/* 
---------------------------------------------
Services Style
--------------------------------------------- 
*/

.services .service-item:hover .icon img {
  margin-top: -10px;
}

.services .service-item {
  position: relative;
  margin-top: 95px;
}

.services .service-item .icon {
  width: 190px;
  height: 190px;
  display: inline-block;
  text-align: center;
  line-height: 190px;
  background-color: #ffde59;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: -95px;
}

.services .service-item .icon img {
  max-width: 86px;
  transition: all .2s;
}

.services .service-item .main-content {
  border-radius: 25px;
  padding: 80px 30px 50px 30px;
  background-color: #ffec9f;
  margin-bottom: 30px;
  margin-right: 80px;
  transition: all .4s;
}

.services .service-item h4 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
  line-height: 30px;
  transition: all .4s;
}

.services .service-item p {
  color: #4a4a4a;
  margin-bottom: 25px;
}

/* 
---------------------------------------------
About Style
--------------------------------------------- 
*/

.about-us {
  position: relative;
  padding: 0px;
  margin-top: 130px;
}

.about-us:before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 84%;
  height: 100%;
  background-color: #f1f0fe;
  content: '';
  border-top-left-radius: 500px;
  border-bottom-left-radius: 500px;
}

.accordion {
  margin-top: -40px;
  margin-bottom: -40px;
  background-color: #7a6ad8;
  border-radius: 40px;
  padding: 80px 50px 50px 50px;
}

.accordion-item {
  background-color: #fff;
  border-radius: 40px !important;
  margin-bottom: 30px;
  border: none;
}

.accordion-item .accordion-button {
  outline: none;
  box-shadow: none;
  border-radius: 40px !important;
}

.accordion-button:not(.collapsed) {
  color: #7a6ad8;
  background-color: #fff;
}

h2.accordion-header button {
  padding: 15px 25px;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
}

.accordion-button::after {
  font-size: 18px;
  font-weight: 500;
  background-image: none;
  content: '+';
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  background-color: #7a6ad8;
  color: #fff;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
  line-height: 32px;
  content: '-';
}

.accordion-body {
  padding: 0px 25px 30px 25px;
  font-size: 14px;
  line-height: 28px;
  color: #4a4a4a;
}

.about-us .section-heading {
  margin-left: 60px;
  margin-bottom: 0px;
}

.about-us .section-heading .main-button {
  margin-top: 50px;
}


/* 
---------------------------------------------
Courses Style
--------------------------------------------- 
*/

.courses {
  margin-top: 20px;
}

.event_filter {
  text-align: center;
}

.event_filter {
  background-color: #f1f0fe;
  border-radius: 50px;
  padding: 15px 40px;
  list-style: none;
  margin: 0 auto 70px;
  max-width: max-content;
}

.event_filter li {
  display: inline-block;
  margin: 0px 20px;
}

.event_filter li a {
  font-size: 14px;
  font-weight: 500;
  color: #1e1e1e;
  transition: all .3s;
}

.event_filter li

.event_filter li a.is_active,
.event_filter li a:hover {
  color: #7a6ad8;
}

.events_item .thumb {
  position: relative;
  border-radius: 25px;
  overflow: hidden;
}

.events_item .thumb img {
  margin-bottom: -5px;
}

.events_item .thumb span.category {
  position: absolute;
  left: 30px;
  top: 30px;
  font-size: 14px;
  text-transform: uppercase;
  color: #000000;
  background-color: rgba(250, 250, 250, 0.95);
  padding: 8px 20px;
  border-radius: 25px;
  font-weight: 500;
  display: inline-block;
}

.events_item .thumb span.price {
  position: absolute;
  right: -30px;
  top: -50px;
  background-color: rgba(122, 106, 216, 0.95);
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: inline-block;
  transition: all .3s;
}

.events_item .thumb span.price h6 {
  margin-top: 72px;
  margin-left: 22px;
  font-size: 28px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
}

.events_item .thumb span.price em {
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  vertical-align: top;
}
 
.events_item {
  background-color: #f1f0fe;
  border-radius: 25px;
  margin-bottom: 30px;
  position: relative;
  justify-content: center;
  align-items: center;
} 

.events_item .down-content {
  padding: 20px 30px 25px 30px;
}

.events_item .down-content span.author {
  color: #9d9c98;
  font-size: 15px;
  display: inline-block;
  margin-bottom: 6px;
}

.events_item .down-content h4 {
  font-size: 22px;
  font-weight: 600;
  transition: all .3s;
}

.events_item:hover .down-content h4 {
  color: #000000;
}

.events_item:hover .thumb span.price {
  transform: scale(1.2);
}

#review1{
  background-image: url(./images/user.jpg);
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 8rem;
  margin-left: 40%;
  margin-top: 2%;
}
#review2{
  background-image: url(./images/kakashi.webp);
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 8rem;
  margin-left: 40%;
  margin-top: 2%;
}
#review3{
  background-image: url(./images/sakshi.jpeg);
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 8rem;
  margin-left: 40%;
  margin-top: 2%;
}
#review4{
  background-image: url(./images/small_dog.jpg);
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 8rem;
  margin-left: 40%;
  margin-top: 2%;
}
/* 
---------------------------------------------
Facts Style
--------------------------------------------- 
*/

.fun-facts {
  position: relative;
  padding: 120px 0px 80px 0px;
  overflow: hidden;
}

.fun-facts:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 95%;
  height: 100%;
  background-color: #7a6ad8;
  content: '';
  border-top-right-radius: 500px;
  border-bottom-right-radius: 500px;
}

.fun-facts:after {
  background: url(./images/contact-dec-01.png);
  position: absolute;
  left: 15%;
  opacity: 0.5;
  top: 0;
  width: 318px;
  height: 119px;
  content: '';
  z-index: 2;
}

.fun-facts .counter {
  text-align: center;
  margin-bottom: 40px;
}

.fun-facts h2 {
  color: #fff;
  font-size: 48px;
  font-weight: 700;
}

.fun-facts h2::after {
  content: '+';
  margin-left: 5px;
}

.fun-facts p {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  margin-top: 15px;
}


/* 
---------------------------------------------
Team Style
--------------------------------------------- 
*/

.team {
  margin-top: 100px;
    padding-top: 140px;
}

.team-member {
  position: relative;
}

.team-member:hover img {
  top: -120px;
}

.team-member img {
  max-width: 220px;
  border-radius: 50%;
  position: absolute;
  top: -110px;
  left: 50%;
  transform: translateX(-110px);
  transition: all .3s;
}

.team-member .main-content {
  border-radius: 25px;
  padding: 140px 30px 40px 30px;
  background-color: #f1f0fe;
  text-align: center;
}

.team-member .main-content span.category {
  color: #7a6ad8;
  font-size: 15px;
}

.team-member .main-content h4 {
  font-size: 22px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 15px;
}

.team-member .main-content ul li {
  display: inline-block;
  margin: 0px 2px;
}

.team-member .main-content ul li a {
  background-color: #fff;
  color: #7a6ad8;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all .3s;
}

.team-member .main-content ul li a:hover {
  background-color: #7a6ad8;
  color: #fff;
}


/* 
---------------------------------------------
Testimonials Style
--------------------------------------------- 
*/

.testimonials {
  position: relative;
  padding: 80px 0px;
  margin-top: 150px;
}

.testimonials:before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 65%;
  height: 100%;
  background-color: #f1f0fe;
  content: '';
  border-top-left-radius: 500px;
  border-bottom-left-radius: 500px;
}

.testimonials .section-heading {
  margin-bottom: 0px;
  margin-left: 60px;
  vertical-align: middle;
}

.testimonials .section-heading p {
  margin-top: 50px;
}

.testimonials .item {
  background-color: #7a6ad8;
  border-radius: 25px;
  padding: 80px;
}

.testimonials .item p {
  font-size: 18px;
  line-height: 40px;
  color: #fff;
  font-style: italic;
  font-weight: 300;
  margin-bottom: 40px;
}

.testimonials .item img {
  border-radius: 50%;
  max-width: 100px;
  float: left;
  margin-right: 25px;
}

.testimonials .item span {
  display: inline-block;
  margin-top: 20px;
  font-size: 15px;
  color: #fff;
}

.testimonials .item h4 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  margin-top: 8px;
}

.testimonials .owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-5px);
  right: -25px;
  text-align: right;
}

.testimonials .owl-nav .owl-prev i,
.testimonials .owl-nav .owl-next i {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  display: inline-block;
  color: #7a6ad8;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.10);
  opacity: 1;
  transition: all .3s;
}

.testimonials .owl-nav .owl-prev i {
  position: absolute;
  bottom: 65px;
}

.testimonials .owl-nav .owl-prev i:hover,
.testimonials .owl-nav .owl-next i:hover {
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}


/* 
---------------------------------------------
Events Style
--------------------------------------------- 
*/

.events .section-heading {
  margin-bottom: 100px;
}

.events .item {
  background-color: #f1f0fe;
  border-radius: 25px;
  position: relative;
  padding: 40px;
  margin-bottom: 96px;
}

.events .item .image {
  position: relative;
}

.events .item .image img {
  position: absolute;
  border-radius: 25px;
  max-width: 260px;
  left: 0;
  top: -70px;
}

.events .item ul li {
  display: inline-block;
  width: 17.5%;
  vertical-align: middle;
}

.events .item ul li:first-child {
  width: 35%;
}

.events .item ul li:nth-of-type(2) {
  width: 28%;
}

.events .item ul li span.category {
  font-size: 14px;
  text-transform: uppercase;
  color: #7a6ad8;
  background-color: #fff;
  padding: 8px 20px;
  border-radius: 25px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 20px;
}

.events .item ul li h4 {
  font-size: 22px;
  font-weight: 600;
}

.events .item ul li span {
  display: inline-block;
  font-size: 14px;
  color: #4a4a4a;
  margin-bottom: 10px;
}

.events .item ul li h6 {
  font-size: 16px;
  color: #7a6ad8;
  font-weight: 600;
}

.events .item a {
  position: absolute;
  right: 0;
  top: 22px;
  background-color: #7a6ad8;
  width: 60px;
  height: 120px;
  display: inline-block;
  text-align: center;
  line-height: 120px;
  font-size: 18px;
  z-index: 1;
  color: #fff;
  border-radius: 60px 0px 0px 60px;
}


/* 
---------------------------------------------
Contact Style
--------------------------------------------- 
*/

.contact-us {
  margin-top: 80px;
  position: relative;
  padding: 50px 0px;
}

.contact-us:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 65%;
  height: 100%;
  background-color: #f1f0fe;
  content: '';
  border-top-right-radius: 500px;
  border-bottom-right-radius: 500px;
}


.contact-us  .section-heading {
  margin-right: 110px;
  margin-bottom: 0px;
}

.contact-us  .section-heading p {
  margin-top: 50px;
}

.contact-us .special-offer {
  margin-top: 50px;
  background-color: #fff;
  border-radius: 25px;
  padding: 30px 120px;
  display: inline-block;
  position: relative;
}

.contact-us .special-offer span.offer {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #7a6ad8;
  width: 80px;
  padding: 30px 8px;
  line-height: 25px;
  height: 120px;
  border-radius: 15px 60px 60px 15px; 
  display: inline-block;
  text-align: center;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  text-align: left;
}

.contact-us .special-offer span.offer em {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
}

.contact-us .special-offer h6 {
  font-size: 15px;
  color: #4a4a4a;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 15px;
}

.contact-us .special-offer h6 em {
  font-style: normal;
  color: #7a6ad8;
  font-weight: 600;
}

.contact-us .special-offer h4 {
  font-size: 22px;
  font-weight: 600;
}

.contact-us .special-offer h4 em {
  font-style: normal;
  color: #7a6ad8;
}

.contact-us .special-offer a {
  position: absolute;
  right: 30px;
  top: 35px;
  width: 50px;
  height: 50px;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  background-color: #7a6ad8;
  color: #fff;
  border-radius: 50%;
}

.contact-us .contact-us-content {
  border-radius: 25px;
  padding: 80px;
  background-color: #7a6ad8;
  position: relative;
  z-index: 1;
}

.contact-us .contact-us-content::before {
  background: url(./images/contact-dec-01.png);
  position: absolute;
  left: 50%;
  transform: translateX(-149px);
  opacity: 0.5;
  top: 0;
  width: 318px;
  height: 119px;
  content: '';
  z-index: 2;
}

.contact-us .contact-us-content::after {
  background: url(./images/contact-dec-02.png);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 183px;
  height: 149px;
  content: '';
  z-index: 2;
}

#contact-form input {
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background-color: rgba(249, 235, 255, 0.15);
  border: none;
  outline: none;
  font-weight: 300;
  padding: 0px 20px;
  font-size: 14px;
  color: #fff;
  margin-bottom: 30px;
  position: relative;
  z-index: 3;
}

#contact-form input::placeholder {
  color: #fff;
}

#contact-form textarea {
  width: 100%;
  height: 120px;
  border-radius: 25px;
  background-color: rgba(249, 235, 255, 0.15);
  border: none;
  outline: none;
  font-weight: 300;
  padding: 20px;
  font-size: 14px;
  color: #fff;
  margin-bottom: 30px;
  position: relative;
  z-index: 3;
}

#contact-form textarea::placeholder {
  color: #fff;
}

#contact-form button {
  border: none;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  background-color: #fff;
  padding: 0px 25px;
  border-radius: 25px;
  color: #7a6ad8;
  transition: all .4s;
  position: relative;
  z-index: 3;
}

#contact-form button:hover {
  opacity: 0.8;
}

.contact-us-content .more-info {
  text-align: center;
  background: rgb(85,0,227);
  background: linear-gradient(90deg, rgba(85,0,227,1) 0%, rgba(198,61,255,1) 100%);
  border-radius: 0px 0px 23px 23px;
  padding: 45px 30px 15px 30px;
}

.contact-us-content .more-info .info-item {
  text-align: center;
  margin-bottom: 30px;
}

.contact-us-content .more-info i {
  font-size: 32px;
  color: #fff;
  margin-bottom: 15px;
}

.contact-us-content .more-info h4 a {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

/* 
---------------------------------------------
Footer Style
--------------------------------------------- 
*/

footer {
  margin-top: 150px;
  position: relative;
  background-color: rgb(51, 55, 61);
  vertical-align: middle;
  min-height: 150px;
  border-radius: 150px 150px 0px 0px;
}
/* rgb(51, 55, 61) */
footer p {
  text-align: center;
  line-height: 150px;
  color: #fff;
  font-weight: 400;
}

footer p a {
  color: #ffde59;
  transition: all .3s;
  position: relative;
  z-index: 3;
}

footer p a:hover {
  opacity: 0.75;
}

footer::before {
  background: url(./images/contact-dec-01.png);
  position: absolute;
  left: 30px;
  transform: rotate(180deg);
  opacity: 1;
  bottom: 0;
  width: 318px;
  height: 119px;
  content: '';
  z-index: 2;
}

/* 
---------------------------------------------
Responsive Style
--------------------------------------------- 
*/

body {
  overflow-x: hidden;
}

@media (max-width: 767px) {
  .header-area {
    top: 0px;
  }
  .header-area .main-nav .logo h1 {
    border-right: none;
    background-color: #ffde59;
  }
  .main-banner .item {
    padding: 80px 30px 120px 30px !important;
  }
  .main-banner .owl-nav {
    left: 30px !important;
  }
  .event_filter  {
    padding: 15px 20px;
  }
  .event_filter li {
    display: inline-block;
    margin: 0px 10px;
  }
}

@media (max-width: 992px) {
  .header-area .main-nav #search input,
  .header-area .main-nav #search i {
    display: none;
  }
  .header-area .main-nav .logo h1 {
    font-size: 34px;
  }
  .header-area .main-nav .logo {
    border-right: none;
  }
  .main-banner {
    padding-top: 100px;
  }
  .main-banner .item {
    padding: 100px 60px 140px 60px;
    margin-left: 0px;
    text-align: center;
  }
  .main-banner .item h2,
  .main-banner .item p {
    width: 100%;
  }
  .main-banner .item h2 {
    font-size: 36px;
    line-height: 50px;
  }
  .main-banner .item .buttons {
    display: inline-block;
    text-align: center;
  }
  .main-banner .item .buttons .main-button {
    margin-right: 0px;
    margin-bottom: 30px;
  }
  .main-banner .owl-nav {
    left: 50% !important;
    transform: translateX(-60px) !important;
    bottom: 60px;
  }
  .main-banner .owl-nav .owl-prev i {
    bottom: 0px;
  }
  .main-banner .owl-nav .owl-next i {
    bottom: 0px;
    position: absolute;
    left: 65px;
  }
  .services {
    margin-top: 80px;
  }
  .about-us {
    margin-top: 100px;
  }
  .accordion {
    padding: 40px 25px 10px 25px;
  }
  #walk,#grooming,#training,#breeding,#birthday,#daycare{
    background-size:110px 110px;
  }
  
  .services .service-item .icon {
    width: 140px;
    height: 140px;
    line-height: 170px;
  }
  .about-us .section-heading {
    margin-left: 0px;
    margin-top: 120px;
  }
  .about-us::before,
  .testimonials::before,
  .contact-us::before {
    display: none;
  }
  .about-us .section-heading p,
  .testimonials .section-heading p,
  .contact-us .section-heading p {
    margin-top: 30px;
  }
  .about-us .section-heading .main-button {
    margin-top: 30px;
  }
  .about-us .main-button a {
    background-color: #7a6ad8;
    color: #fff;
  }
  .courses {
    margin-top: 10px;
  }
  .event_filter ul li {
    display: block;
    margin: 12px 5px;
  }
  .fun-facts {
    margin-top: 70px;
  }
  .fun-facts::before {
    width: 95%;
  }
  .team {
    margin-top: 160px;
  }
  .team-member {
    margin-bottom: 140px;
  }
  .testimonials {
    margin-top: -50px;
    padding: 0px;
  }
  .testimonials .section-heading {
    margin-left: 0px;
    margin-top: 40px;
  }
  .testimonials .item {
    padding: 40px 40px 60px 40px;
  }
  .testimonials .item h4 {
    font-size: 17px;
  }
  .testimonials .item img {
    margin-right: 15px;
  }
  .testimonials .owl-nav {
    display: none;
  }
  .events {
    margin-top: 80px;
  }
  .events .item {
    margin-bottom: 66px;
  }
  .events .item .image img {
    position: relative;
    max-width: 100%;
  }
  .events .item ul {
    margin-top: -40px;
  }
  .events .item ul li:first-child {
    width: 100% !important;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ddd;
  }
  .events .item ul li:nth-child(2) {
    width: 40% !important;
  }
  .events .item ul li {
    display: inline-block;
    width: 28% !important;
  }
  .events .item ul li:last-child {
    text-align: right;
  }
  .events .item a {
    bottom: 150px;
    top: auto;
  }
  .events .item ul li h6 {
    font-size: 14px;
  }
  .contact-us {
    padding-top: 20px;
  }
  .contact-us .section-heading {
    margin-right: 0px;
  }
  .contact-us .special-offer {
    background-color: #f1f0fe;
    margin-bottom: 60px;
    padding: 34px 30px 34px 100px;
    width: 100%;
  }
  .contact-us .special-offer h4 {
    font-size: 17px;
  }
  .contact-us .special-offer a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    top: 15px;
    right: 15px;
  }
  .contact-us .contact-us-content {
    padding: 30px;
  }
  footer {
    margin-top: 30px;
  }
}

@media (max-width: 1200px) {
  .header-area .main-nav #search input {
    width: 200px;
  }
  .header-area .main-nav .nav li.has-sub:after {
    right: 15px;
  }
  .fun-facts:before {
    width: 95%;
  }
}

 
.header-area {
  background-color: #ffde59;
  border-bottom-left-radius: 20px; /* Adjust the radius value as needed */
  border-bottom-right-radius: 20px; /* Adjust the radius value as needed */
  padding: 20px; /* Adjust padding as needed */
  /* Additional styling for the navbar */
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px; /* Adjust height as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds a slight shadow for better visibility */
}

#walk{
  background-image: url(./images/dog-walking.webp);
  /* Center the image horizontally and align it to the top */
  background-repeat: no-repeat;
  background-position: center ;
  
}

#grooming{
  background-image: url(./images/trainer.webp);
  /* Center the image horizontally and align it to the top */
  background-repeat: no-repeat;
  background-position: center ;
  
}
#training{
  background-image: url(./images/groomer.webp);
  /* Center the image horizontally and align it to the top */
  background-repeat: no-repeat;
  background-position: center ;
  
}
#breeding{
  background-image: url(./images/breeding.webp);
  /* Center the image horizontally and align it to the top */
  background-repeat: no-repeat;
  background-position: center ;
  
}

#birthday{
  background-image: url(./images/birthday.webp);
  /* Center the image horizontally and align it to the top */
  background-repeat: no-repeat;
  background-position: center ;
  
}
#daycare{
  background-image: url(./images/daycare.png);
  /* Center the image horizontally and align it to the top */
  background-repeat: no-repeat;
  background-position: center ;
  
}


.mid-banner {
  background-image: url('./images/mid-banner.webp'); /* Replace with your image path */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent image repetition */
  background-size: contain; /* Ensure the image covers the entire div */
  height: 345px; /* Adjust the height as needed */
  display: flex; /* Flexbox to center content */
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */
  color: #fff; /* White text color for contrast */
  text-align: center; /* Center align text */
  padding: 20px; /* Padding inside the div */
  box-sizing: border-box; /* Ensure padding is included in the width/height calculations */
  width: 100vw; /* Full width of the viewport */
  margin-top: 40px;
}

.independence-banner {
  background-image: url('./images/IndependenceDay.png'); /* Replace with your image path */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent image repetition */
  background-size: contain; /* Ensure the image covers the entire div */
  height: 345px; /* Adjust the height as needed */
  display: flex; /* Flexbox to center content */
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */
  color: #fff; /* White text color for contrast */
  text-align: center; /* Center align text */
  padding: 20px; /* Padding inside the div */
  box-sizing: border-box; /* Ensure padding is included in the width/height calculations */
  width: 100vw; /* Full width of the viewport */
  margin-top: -200px;
  margin-bottom: 120px;
}
/* Responsive Adjustments */
@media (max-width: 768px) {
  .mid-banner {
    height: 50vh; /* Adjust the height for smaller screens */
    padding: 15px; /* Adjust padding for smaller screens */
  }
  .independence-banner {
    margin-top: -200px;
    margin-bottom: -170px;
    width: 103vw;
  }
}

@media (max-width: 480px) {
  .mid-banner {
    height: 40vh; /* Adjust the height for very small screens */
    padding: 10px; /* Adjust padding for very small screens */
  }
}


.experience {
  background: -webkit-gradient(linear, left top, left bottom, from(white), to(#ffde59));
  background: -o-linear-gradient(top, white, #ffde59);
  background: linear-gradient(to bottom, white, #ffde59);
  
}

.bg-1100{
     
  background-color: rgb(253, 220, 91) !important;
 

}

.bg-1100  {
  
  background: -webkit-gradient(linear, left top, left bottom, from(#ffde59), to(white));
  background: -o-linear-gradient(top, #ffde59, white);
  background: linear-gradient(to bottom, #ffde59, white);
 
}

/* .experience {
  background: -webkit-gradient(linear, left top, left bottom, from(white), to(black));
  background: -o-linear-gradient(top, white, black);
  background: linear-gradient(to bottom, white, black);
}

.bg-1100{
     
  background-color: rgb(4, 4, 4) !important;
 

}
 

.wave{
  background-image:url(./images/Wave_2.svg);
  background-repeat: no-repeat;
} */

#gallery1{
  background-image: url(./images/gal1.webp);
  height:325px;
   background-position-x: center;
   background-size: cover;
  background-repeat: no-repeat;
}
#gallery2{
  background-image: url(./images/gal2.webp);
  height:325px;
  background-size: cover;
  background-repeat: no-repeat;
}
#gallery3{
  background-image: url(./images/gal3.webp);
  height:325px;
  background-size: cover;
  background-repeat: no-repeat;
  
  background-repeat: no-repeat;
}
#gallery4{
  background-image: url(./images/daycare1.webp);
  height:325px;
  background-repeat: no-repeat;
  background-size: cover;
}
#gallery5{
  background-image: url(./images/clean1.webp);
  height:325px;
  background-repeat: no-repeat;
  background-size: cover;
}
#gallery6{
  background-image: url(./images/gal4.webp);
  height:325px;
  background-repeat: no-repeat;
  background-size: cover;
}

 #dots{
  background-image: url(./images/Dots.webp);
  background-size: contain;
  background-repeat: no-repeat;
  height: 100px;
 }
 
.swiper-theme-container {
  height: 21.875rem !important;
  width: 37.5rem !important;
  overflow: hidden;
  margin-bottom: 50px;
}
.swiper-theme-container .swiper {
  height: 100%;
  width: 100%;
  margin: auto;
  margin-top: 50px;
}
@media (min-width: 768px) {
  .swiper-theme-container {
    width: 45.65rem !important;
   
  }
  
.swiper-theme-container .swiper {
  
  margin-top: 50px;
}
}
@media (min-width: 992px) {
  .swiper-theme-container {
    width: 45.65rem !important;
    height: 37.5rem !important;
    margin: auto;
  }
   
}

.swiper-slide {
  margin: auto;
  
  color: #161C2D;
  border-radius: 0.85rem;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.82);
  -webkit-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  height: min-content;
  
}
.swiper-slide:hover, .swiper-slide:focus {
  background-color: #fff;
}
.swiper-slide .card-image {
  width: 5.6rem;
  height: 5.6rem;
  border: 2px solid #979797;
}
@media (min-width: 992px) {
  .swiper-slide .card-image {
    width: 6.938rem;
    height: 6.938rem;
  }
}

.swiper-slide-active {
  background-color: #fff;
}

.swiper-slide-next, .swiper-slide-prev {
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}
.swiper-slide-next:hover, .swiper-slide-next:focus, .swiper-slide-prev:hover, .swiper-slide-prev:focus {
  background-color: #fff;
}



#logo{
  background-image: url(./images/barkigo-logo.webp);
  width: 140px;
  z-index: 10; /* Adjust the z-index to a higher value to bring it forward */
  background-size: contain; /* Ensure the background image fits within the container */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  background-position: center;
  margin-right: 140px;
}
#logo1{
  background-image: url(./images/logo.png);
  width: 420px;
  z-index: 10; /* Adjust the z-index to a higher value to bring it forward */
  background-size: contain; /* Ensure the background image fits within the container */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  background-position: center;
}
.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}

/* 
#courses,container:before {

  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 65%;
  height: 100%;
  background-color: #fbecb1;
  content: '';
  border-top-right-radius: 500px;
  border-bottom-right-radius: 500px;
} */

 
#bottom-logo {
  background-image: url(./images/bottomlogo.png);
  background-position: center top; /* Center the image horizontally and align it to the top */
  background-repeat: no-repeat;
  background-size: contain; /* Scale the image to cover the entire container */
  height: 110px; /* Set a fixed height for the .main-banner */
  width: 220px;
  margin: 0 auto; /* Center the div horizontally */
}

@media (max-width: 1024px) {
  .main-banner {
    max-width: 100%; /* Ensures the image does not exceed its container */
    height: 800px;
  }
  
}

/* Media query for mobile phones */
@media (max-width: 768px) {
  .main-banner  {
    
    margin-top: 67px;
    width: 100vw;/* Ensures the image does not exceed its container */
    height: 300px; 
      /* adjust styles for mobile devices */
  }
  .header-area{
    width: 100vw;
    height: 40px;
  }
  .header-area {
    
    /* padding: 0px 15px; */
    height: 80px;
    box-shadow: none;
    text-align: center;
}
.header-area .main-nav .menu-trigger {
  cursor: pointer;
  position: absolute;
  top: 33px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 20px;
  display: none;
}


#logo1 {
  background-image: none;
}
.bg-1100{
  margin-bottom: 220px;
}
footer{
   
  border-radius: 70px 70px 0px 0px;
  height: 200px;
}

#bottom-logo{
  width: 35%;
}
.social-menu  {
  position: absolute;
  margin-top: 10px;
  
  left: 50%;
  padding: 0;
  z-index: 3;
}
#copyright{
  margin-top: 30px;
  height: 10px;
}
footer p{
   margin-top: 50px;
  border-radius: 70px 70px 0px 0px;
 
  line-height: 65%;
  font-size: 8px;
}

.swiper-slide{
  align-items: center;
  padding-left: 10px;
  margin-left: 14%;
}

#dog-container{
  margin-left: 20%;;
}
.section-heading h2 {
  font-size: 22px;
  margin-top: 10px;
}
.service-item .main-content  h4{
  font-size: 19px;
}
.service-item .main-content  p{
  font-size: 13px;
}

#review1{margin-left: 35%;
}
}



.background-header {
  background-color: rgb(28, 30, 33) !important;
  border-radius: 0px 0px 25px 25px;
  height: 80px !important;
  position: fixed !important;
  top: 0 !important;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15) !important;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
}
 #anim{
  width: 300px;
 visibility: hidden;
 
  margin-top: 340px;
  margin-left: 450px;
}

#anim button{
  background-color: transparent;
  border-radius: .7rem;
  height: 40px;
  font-size: 18px;
  font: sans-serif;
}
@media (max-width: 768px) {
  #anim{
    width: 110px;
    
   
    margin-top: 40px;
    margin-left: 10px;
  }
}


@media (max-width: 376px) {

  .swiper-slide{
margin-left: 10%;
margin-right:5%;
  }
}

 

/* #anim{
  display:flex;
  align-items: center;
  justify-content: center;
  width: 600px;
 
  margin-top: 120px;
  margin-left: 50px;
}
#anim h1{
  padding-left: 30px;
   
  font-weight: bold;
} */

  


/*dogg */
:root {
  --dog-width: 100px;
  --interval: 200ms;
  --color-gray: #eaebec;
  --easing: ease-in-out;
}

.ball {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-top: 4rem;
  z-index: 0;
}

.ball:after {
  content: "";
  position: absolute;
  display: block;
  margin-top: 130px;
 
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: inset 0 -8px 0 0 rgba(0, 0, 0, 0.2);
  background: #9364f0;
  z-index: 1;
}


.ball:focus {
  outline: none;
}

.ball:focus:after {
  animation: bounce calc(var(--interval) * 2) infinite alternate;
}

@keyframes bounce {
  from {
    transform: scale(2);
    animation-timing-function: ease-in;
  }
  to {
    transform: scale(0.8);
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
}

@keyframes bounce-shadow {
  from {
    transform: scale(2.5, 2.6) translateY(-50%);
    animation-timing-function: ease-in;
  }
  to {
    transform: scale(0.5) translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
}

.ball:focus:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.05);
  animation: bounce-shadow calc(var(--interval) * 2) infinite alternate;
  z-index: -10;
}

.dog {
  width: var(--dog-width);
  height: var(--dog-width);
  z-index: 1;
}

.dog:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.03);
  transform: translateY(-30%) scale(1.5);
}

.dog * {
  position: absolute;
}

.dog-body {
  top: -50%;
  animation: dog-body var(--interval) var(--easing) infinite alternate;
}

.dog-body:before {
  content: "";
  position: absolute;
  bottom: 90%;
  right: 50%;
  width: 90%;
  height: 90%;
  border-top-left-radius: 100%;
  border-bottom-left-radius: 10%;
  border-top-right-radius: 10%;
  background: rgba(255, 255, 255, 0.4);
  transform-origin: right bottom;
  animation: dog-tail-blur var(--interval) calc(var(--interval) / 6) var(--easing) infinite alternate both;
}

@keyframes dog-tail-blur {
  from {
    transform: rotate(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    transform: rotate(90deg);
    opacity: 0;
  }
}

@keyframes dog-body {
  from {
    transform: translateX(-10%);
  }
  to {
    transform: translateX(10%);
  }
}

.dog-head {
  animation: dog-head calc(var(--interval) * 9) cubic-bezier(0.11, 0.79, 0, 0.99) infinite;
}

@keyframes dog-head {
  from,
  to {
    transform: rotate(45deg);
  }
  33.3% {
    transform: rotate(-45deg);
  }
  66.6% {
    transform: rotate(0);
  }
}

.dog-torso {
  top: -20%;
  animation: dog-torso var(--interval) var(--easing) infinite alternate-reverse;
}

@keyframes dog-torso {
  from {
    transform: translateX(-5%);
  }
  to {
    transform: translateX(5%);
  }
}

.dog-eyes {
  width: 60%;
  top: 55%;
  left: 20%;
  z-index: 1;
}

.dog-eyes:before {
  content: "";
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  position: absolute;
  background: orange;
  top: -10px;
  left: -10px;
  z-index: 0;
  border: 4px solid white;
  border-left-width: 0;
  border-bottom-width: 0;
  border-top-width: 0;
  transform: rotate(-45deg);
}

.dog-eye {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #000;
  z-index: 1;
  animation: dog-eye calc(var(--interval) * 9) infinite;
}

@keyframes dog-eye {
  from,
  to {
    animation-timing-function: step-end;
    opacity: 1;
  }
  50%,
  55% {
    animation-timing-function: step-start;
    opacity: 0;
  }
}

.dog-eye:first-child {
  left: 0;
}

.dog-eye:last-child {
  right: 0;
}

.dog-muzzle {
  width: 60%;
  left: 20%;
  height: 50%;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  background: white;
  bottom: -15%;
}

.dog-muzzle:before,
.dog-muzzle:after {
  content: "";
  display: block;
  position: absolute;
}

.dog-muzzle:before {
  width: 6px;
  height: 20px;
  bottom: 0;
  left: calc(50% - 3px);
  background: var(--color-gray);
}

.dog-muzzle:after {
  background: black;
  width: 20px;
  height: 15px;
  bottom: 12px;
  left: calc(50% - 10px);
  border-bottom-left-radius: 60% 60%;
  border-bottom-right-radius: 60% 60%;
  border-top-left-radius: 50% 40%;
  border-top-right-radius: 50% 40%;
}

.dog-tongue {
  width: 40px;
  height: 100%;
  left: calc(50% - 20px);
  z-index: -1;
  transform-origin: center top;
  animation: dog-tongue calc(var(--interval) * 9) -50ms ease-in-out infinite;
}

@keyframes dog-tongue {
  from,
  to {
    transform: rotate(0);
  }
  16.67% {
    transform: rotate(30deg);
  }
  33.33%,
  66.67% {
    transform: rotate(0);
  }
  50%,
  83.33% {
    transform: rotate(-20deg);
  }
}

.dog-tongue:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background: #fd3163;
  animation: dog-tongue-inner calc(var(--interval) / 2) var(--easing) infinite alternate;
}

@keyframes dog-tongue-inner {
  from {
    transform: translateY(5%);
  }
  to {
    transform: translateY(22%);
  }
}

.dog-ears {
  width: 40%;
  top: 25%;
  left: 30%;
  animation: dog-ears calc(var(--interval) * 9) calc(var(--interval) / 2) ease infinite;
}

@keyframes dog-ears {
  42.3%,
  71.6% {
    transform: rotate(-5deg);
  }
  50.3%,
  79.6% {
    transform: rotate(5deg);
  }
  5% {
    transform: rotate(5deg);
  }
  12% {
    transform: rotate(-5%);
  }
  from,
  33.3%,
  66%,
  to {
    transform: rotate(0);
  }
}

.dog-ear {
  bottom: -10px;
  height: 50px;
  width: 50px;
  background: var(--color-gray);
  animation-duration: calc(var(--interval) * 2);
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

.dog-ear:first-child {
  border-bottom-left-radius: 80%;
  border-top-right-radius: 80%;
  right: 100%;
  box-shadow: inset -15px 15px 0 1px white;
  transform-origin: right bottom;
  transform: rotate(10deg);
}

.dog-ear:last-child {
  border-top-left-radius: 80%;
  border-bottom-right-radius: 80%;
  left: 100%;
  box-shadow: inset 15px 15px 0 0 white;
  transform-origin: left bottom;
  transform: rotate(-10deg);
}

.dog-tail {
  --tail-width: 22px;
  width: var(--tail-width);
  height: calc(var(--tail-width) * 1.1);
  background: white;
  bottom: 40%;
  border-radius: calc(var(--tail-width) / 2);
  left: calc(50% - (var(--tail-width) / 2));
  transform-origin: center bottom;
}

.dog-tail .dog-tail {
  animation: dog-tail-segment var(--interval) var(--easing) infinite alternate;
}

@keyframes dog-tail-segment {
  from {
    transform: rotate(-10deg);
  }
  to {
    transform: rotate(10deg);
  }
}

.dog-body > .dog-tail {
  bottom: 90%;
  animation: dog-tail var(--interval) var(--easing) infinite alternate;
}

@keyframes dog-tail {
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(45deg);
  }
}

.dog-body,
.dog-torso,
.dog-head {
  border-radius: 50%;
  background: white;
  position: absolute;
  height: 100%;
  width: 100%;
}

.dog-body,
.dog-torso {
  box-shadow: inset 0 -15px 0 0 var(--color-gray);
}

 
 
.dog,.dog-body,.dog-tail,.dog-torso,.dog-head,.dog-ears,.dog-muzzle,.ball:before,
.dog,.dog-body,.dog-tail,.dog-torso,.dog-head,.dog-ears,.dog-muzzle,.ball:after {
  box-sizing: border-box;
  position: absolute;
}
.ball:after{
align-items: center;
}
 


/* socials */
 

.social-menu ul{
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0;
  margin: 0;
  transform: translate(-50%, -50%);
  display: flex;
}

.social-menu ul li{
  list-style: none;
  margin: 0 15px;
}

.social-menu ul li .fab{
  font-size: 20px;
  line-height: 40px;
  transition: .3s;
  color: #000;
}

.social-menu ul li .fab:hover{
  color: #fff;
}

.social-menu ul li a{
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  transition: .6s;
  box-shadow: 0 5px 4px rgba(0,0,0,.5);
}

.social-menu ul li a:hover{
  transform: translate(0, -10%);
}

.social-menu ul li:nth-child(1) a:hover{
  background-color: rgba(0, 0, 0, 0.829);
}
.social-menu ul li:nth-child(2) a:hover{
  background-color: #E4405F;
}
.social-menu ul li:nth-child(3) a:hover{
  background-color: #0077b5;
}
.social-menu ul li:nth-child(4) a:hover{
  background-color: #000;
}


.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;  
  /* --bs-nav-link-color:rgb(13,110,253); */
  /* --bs-nav-link-hover-color: var(--bs-link-hover-color); */
  /* --bs-nav-link-disabled-color: var(--bs-secondary-color); */
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.background-header .main-nav .menu-trigger {
  top: 20px;
}
/* @media (max-width: 767px) {
  .header-area .menu-trigger {
      display: block !important;
  }
} */



.smallnav {
  background-color: rgb(28, 30, 33);
  color: #ffde59;
  border-radius: 1.3rem;
  border: 1px solid #ffde59;
  font-size: 12px;
  padding: 10px;
  display: none; /* Hidden by default */
  float: right;
  margin-right: 10px;
}
@media (max-width: 767px) {
  .smallnav{
    float: left;
display: block;
 
 
  }
}


/* #vidcontainer{
  height:120px;
  width: 100%;
  margin-top: 40px;
  align-items: center;
}

 #vid{
  height:80%;
  margin-bottom: -40px;
  margin-top: 10px;
  margin-left: 35%;
 } */
 #vidcontainer {
  height: 220px;
  /* width: 100%; */
  margin-top: 40px;
  display: flex; /* Use Flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  position: relative;
  border-radius: 2rem;
}

#vid {
  /* height: 80%; */
  margin-top: 100px;
  margin: 10px auto; /* Center vertically within the container */
}
@media (max-width: 767px) {
  #vid{
   
    margin-top: -110px;
    /* height: 200px; */
 
  }
  #vidcontainer{
     height:300px;  
    margin-top: -110px;
    
  }
}

@media (max-width: 768px) {
  body, html {
      overflow-x: hidden;
  }
}




 
 /* preloader */
 .ajax-loader {
	position: absolute;
	top: 25%;
	left: 50%;
	transform-origin: 50% 50%;
	transform: rotate(90deg) translate(-50%, 0%);
	font-size: 50px;
	width: 1em;
	height: 3em;
	color: orange;
}

.ajax-loader .paw {
	width: 1em;
	height: 1em;
	animation: 2050ms pawAnimation ease-in-out infinite;
	opacity: 0;
}

.ajax-loader .paw svg {
	width: 100%;
	height: 100%;
}

.ajax-loader .paw .icon {
	fill: currentColor;
}

.ajax-loader .paw:nth-child(odd) {
	transform: rotate(-10deg);
}

.ajax-loader .paw:nth-child(even) {
	transform: rotate(10deg) translate(125%, 0);
}

.ajax-loader .paw:nth-child(1) {
	animation-delay: 1.25s;
}

.ajax-loader .paw:nth-child(2) {
	animation-delay: 1s;
}

.ajax-loader .paw:nth-child(3) {
	animation-delay: 0.75s;
}

.ajax-loader .paw:nth-child(4) {
	animation-delay: 0.5s;
}

.ajax-loader .paw:nth-child(5) {
	animation-delay: 0.25s;
}

.ajax-loader .paw:nth-child(6) {
	animation-delay: 0s;
}

.no-cssanimations .ajax-loader .paw {
	opacity: 1;
}

@keyframes pawAnimation {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}


/* -------------------------------------------------------------------
 * ## header search trigger
 * ------------------------------------------------------------------- */
 /* .header__search-trigger {
  z-index: 1;
  display: block;
  color: #000000;
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: url(./images/icons/icon-search.svg);
  position: fixed;
  right: 40px;
  top: 6.4rem;
}

.header__search-trigger::before {
  content: "";
  display: block;
  width: 1px;
  height: calc(100vh - 200px);
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  right: 48px;
  top: auto;
  bottom: 0;
}

.header__search-trigger::after {
  content: "Search";
  font-family: "Heebo", sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 5px;
  width: auto;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 1.6rem 100%;
  transform-origin: 1.6rem 100%;
  position: absolute;
  top: 72px;
  left: 0;
} */

/* ------------------------------------------------------------------- 
 * ## header-search
 * ------------------------------------------------------------------- */
.header__search {
  z-index: 400;
  display: block;
  text-align: center;
  background-color: #ffde59;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.header__search-form {
  width: 100%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  position: absolute;
  top: 50%;
}

.header__search-form label {
  color: #000000;
}

/* .header__search-form::after {
  content: "Press Enter to begin your search.";
  display: block;
  letter-spacing: 0.6px;
  font-size: 1.6rem;
  margin-top: 3.2rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
} */

.header__search-form input[type="search"] {
  background-color: transparent;
  color: #000000;
  height: auto;
  width: 100%;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  font-size: 6rem;
  line-height: 1.6;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  max-width: 680px;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  margin: 0 auto;
  text-align: center;
}

.header__search-form input[type="search"]::-webkit-input-placeholder {

  /* WebKit, Blink, Edge */
  color: #000000;
}

.header__search-form input[type="search"]:-moz-placeholder {

  /* Mozilla Firefox 4 to 18 */
  color: #000000;
  opacity: 1;
}

.header__search-form input[type="search"]::-moz-placeholder {

  /* Mozilla Firefox 19+ */
  color: #000000;
  opacity: 1;
}

.header__search-form input[type="search"]:-ms-input-placeholder {

  /* Internet Explorer 10-11 */
  color: #000000;
}

.header__search-form input[type="search"]::-ms-input-placeholder {

  /* Microsoft Edge */
  color: #000000;
}

.header__search-form input[type="search"]::placeholder {

  /* Most modern browsers support this now. */
  color: #000000;
}

.header__search-form input[type="search"].placeholder {
  color: #000000 !important;
}

/* .header__search-form input[type="search"]:focus {
  outline: none;
} */

.header__search-form input[type="submit"] {
  display: none;
}

body.search-is-visible {
  overflow: hidden;
}

.search-is-visible .header__search {
  opacity: 1;
  visibility: visible;
}

/* ------------------------------------------------------------------- 
 * ## close button for search 
 * ------------------------------------------------------------------- */
.header__search-close {
  position: absolute;
  display: block;
  width: 45px;
  height: 45px;
  top: 21px;
  left: 50%;
  margin-left: -23px;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

.header__search-close::before,
.header__search-close::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 2px;
  height: 20px;
  top: 12px;
  left: 22px;
  background-color: #000000;
}

.header__search-close::before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.header__search-close::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}




/* Form */
.inp,
button,select {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -300%);
  display: block;
  width: 70vw;
  opacity: 0;
  visibility: visible;
  pointer-events: none;
  transition: all 0.5s cubic-bezier(0.4, 0.25, 0.8, 0.3);
}

.inp {
  padding: 0.25rem 0;
  border: 0;
  border-bottom: 1px solid #000000; /* replace with your $primary color */
  outline: 0;
  background: transparent;
  color: #1a1616;
  font-size: 3rem;
  line-height: 4rem;
  letter-spacing: 0.125rem;
  transition: all 0.5s cubic-bezier(0.4, 0.25, 0.8, 0.3);
}

.inp::selection {
  background: rgba(0, 123, 255, 0.25); /* replace with rgba($primary, 0.25) */
}

button,
.signup-button {
  padding: 0.25em 0;
  border: 0;
  outline: 0;
  background: #1d1e1e; /* replace with your $primary color */
  color: rgba(255, 255, 255, 0.85);
  font-size: 2rem;
  width: 220px;
  line-height: 3.6rem;
  letter-spacing: 0.0625rem;
  box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.25);
  text-shadow: 0 -2px 0 rgba(0, 0, 0, 0.25), 0 1px 0 rgba(255, 255, 255, 0.2);
}

/* .inp:focus,
button:focus {
  opacity: 1;
  transform: translate(-50%, -100%);
  pointer-events: auto;
  transition: all 0.4s cubic-bezier(0.1, 0.45, 0.1, 0.85) 0.5s;
  z-index: 10;
} */

.inpvis{
  opacity: 1;
  transform: translate(-50%, -100%);
  pointer-events: auto;
  transition: all 0.4s cubic-bezier(0.1, 0.45, 0.1, 0.85) 0.5s;
  z-index: 10;
}

input:focus ~ input,
input:focus ~ button {
  transform: translate(-50%, 500%);
  transition: all 0.5s ease-in;
}

input:focus ~ label .label-text {
  transform: translate(-50%, 300%);
  transition: all 0.5s ease-in;
}

input:focus ~ .tip {
  opacity: 1;
}

/* input:focus ~ .signup-button,
button:focus ~ .signup-button {
  opacity: 0;
} */
input:focus ~ .label-above-signup,
button:focus ~ .label-above-signup{
  opacity: 0;
}
input:focus + label .label-text {
  opacity: 1;
  transform: translate(-50%, -100%);
  transition: all 0.3s cubic-bezier(0.1, 0.45, 0.1, 0.85) 0.4s;
}

input:focus + label .nav-dot:before {
  background: #5959ff; /* replace with darken($primary, 5%) */
  box-shadow: 0 0 0 0.45rem #5290cf, 0 0 0.05rem 0.56rem #007bff;  
}

.tip {
  position: fixed;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  opacity: 0;
  color: #080707;
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  text-align: right;
  transition: opacity 0.25s 0.5s;
  margin-top: 20px;
}

.signup-button,
.signup-button-trigger {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 220px;
  padding: 0.25rem 0;
  line-height: 3.6rem;
  text-align: center;
  pointer-events: none;
  cursor: pointer;
  transition: opacity 0.4s 0.3s;
}

.signup-button-trigger {
  opacity: 0;
  pointer-events: auto;
}

.label-text {
  position: fixed;
  top: calc(50% - 4rem);
  left: 50%;
  transform: translate(-50%, -300%);
  width: 70vw;
  padding: 3.125rem 0 1.5rem;
  text-transform: uppercase;
  color: #020202;
  opacity: 0;
  font-size: 1.425rem;
  font-weight: 300;
  letter-spacing: 0.125rem;
  pointer-events: none;
  transition: all 0.4s cubic-bezier(0.4, 0.25, 0.8, 0.3) 0.05s;
}

.nav-dot {
  cursor: pointer;
  position: fixed;
  padding: 0.625rem 1.25rem 0.625rem 0.625rem;
  top: 52%;
  right:4.25rem;
}

.nav-dot:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 0.375rem;
  height: 0.375rem;
  margin-right: 0.625rem;
  position: fixed;
  background-color: #000000; /* replace with lighten($background, 3%) */
  border: 0;
  transition: all 0.25s;
}

.nav-dot:hover:before {
  width: 0.625rem;
  height: 0.625rem;
  margin-top: -0.125rem;
  margin-left: -0.125rem;
  background-color: #0056b3; /* replace with darken($primary, 5%) */
}

/* Specific positions for each dot */
label[for="input-1"] .nav-dot {
  margin-top: calc(-150px + 25px * 1);
}
label[for="input-2"] .nav-dot {
  margin-top: calc(-150px + 25px * 2);
}
label[for="input-3"] .nav-dot {
  margin-top: calc(-150px + 25px * 3);
}
label[for="input-4"] .nav-dot {
  margin-top: calc(-150px + 25px * 4);
}
label[for="input-5"] .nav-dot {
  margin-top: calc(-150px + 25px * 5);
}
 
 
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* html,
body {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom right,
    #f8f9fa,  
    #343a40  
  );
  font-family: "Lato", sans-serif;
}

form {
  width: 100%;
  height: 100%;
  overflow: hidden;
} */

.label-above-signup {
  position: fixed;
  top: calc(50% - 9rem); /* Adjust based on your design */
  left: 50%;
  transform: translate(-50%, -100%);
  width: 70vw;
  padding: 0.625rem 0;
  text-transform: uppercase;
  color: #020202;
  opacity: 1;
  font-size: 1.325rem;
  font-weight: 300;
  letter-spacing: 0.125rem;
  text-align: center;
  
}
.styled-select {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -300%);
  display: block;
  width: 60vw;
   
  pointer-events: none;
  transition: all 0.5s cubic-bezier(0.4, 0.25, 0.8, 0.3);
}
.styled-select {
  padding: 0.25rem 0;
  border: 0;
  border-bottom: 1px solid #000000; /* replace with your $primary color */
  outline: 0;
  background: transparent;
  color: #1a1616;
  font-size: 2rem;
  line-height: 4rem;
  letter-spacing: 0.125rem;
  transition: all 0.5s cubic-bezier(0.4, 0.25, 0.8, 0.3);
}
/* 
input:focus,
button:focus,
.styled-select:focus {
  opacity: 1;
  transform: translate(-50%, -100%);
  pointer-events: auto;
  transition: all 0.4s cubic-bezier(0.1, 0.45, 0.1, 0.85) 0.5s;
  z-index: 10;
}

input:focus ~ input,
input:focus ~ button,
.styled-select:focus ~ input,
.styled-select:focus ~ button {
  transform: translate(-50%, 500%);
  transition: all 0.5s ease-in;
}

input:focus ~ label .label-text,
.styled-select:focus ~ label .label-text {
  transform: translate(-50%, 300%);
  transition: all 0.5s ease-in;
}

input:focus ~ .tip,
.styled-select:focus ~ .tip {
  opacity: 1;
}

input:focus ~ .signup-button,
button:focus ~ .signup-button,
.styled-select:focus ~ .signup-button {
  opacity: 0;
}

input:focus + label .label-text,
.styled-select:focus + label .label-text {
  opacity: 1;
  transform: translate(-50%, -100%);
  transition: all 0.3s cubic-bezier(0.1, 0.45, 0.1, 0.85) 0.4s;
}

input:focus + label .nav-dot:before,
.styled-select:focus + label .nav-dot:before {
  background: #5959ff;  
  box-shadow: 0 0 0 0.45rem #5290cf, 0 0 0.05rem 0.56rem #007bff;
}

 */


/* Form */
 
.nexttip {
  position: fixed;
  top: 57%;
   
  transform: translate(-50%, -50%);
  width: 40px;
  opacity: 1;
  color: #b5b5b5;
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  text-align: right;
  transition: opacity 0.25s 0.5s;
  margin-top: 10px;
  background: black;
  border: none;
  cursor: pointer;
   
}

input:focus ~ .nexttip,
select:focus ~ .nexttip {
  opacity: 1;
}
